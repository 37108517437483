import React/*, {useRef}*/ from "react";
// import { useScrollMonitor } from 'scrollmonitor-hooks';
import { Waypoint } from "react-waypoint";

const WatchedElement = (props) => {
  // const callbacks = {
  //   enterViewport: (watcher) => {
  //     console.log(`entered viewport top: ${watcher.top} bottom: ${watcher.bottom}`)
  //   },
  //   exitViewport: (watcher) => {
  //     console.log(`exited viewport top: ${watcher.top} bottom: ${watcher.bottom}`)
  //   }
  // }
  //   // 1. Create a ref
  // const ref = useRef(null);
  // // 2. Pass it to `useScrollState`
  // const scrollState = useScrollMonitor(ref, callbacks);
  //
  // // 3. Get the current scroll state.
  // // if (scrollState.isInViewport) {
  // //   props.handleInViewport();
  // // } else {
  // //
  // // }
  const handleWaypointEnter = ({ previousPosition, currentPosition, event }) => {
    // console.log(`Entering with previousPosition: ${previousPosition}, currentPosition: ${currentPosition}`)

    if (previousPosition === Waypoint.above) {
      // scrolling down
      //console.log("Entering from scroll up");
      if (props.handleEnterViewportScrollUp) {
        props.handleEnterViewportScrollUp();
      }

    } else if (previousPosition === Waypoint.below) {
      //console.log("Entering from scroll down");

      if (props.handleEnterViewportScrollDown) {
        props.handleEnterViewportScrollDown();
      }
    }
  };

  const handleWaypointLeave = ({ previousPosition, currentPosition, event }) => {
    // console.log(`Exiting with previousPosition: ${previousPosition}, currentPosition: ${currentPosition}`)

    if (currentPosition === Waypoint.above) {
      // scrolling down
      //console.log("Exiting from scroll down");
      if (props.handleExitViewportScrollDown) {
        props.handleExitViewportScrollDown();
      }
    } else if (currentPosition === Waypoint.below) {
      //console.log("Exiting from scroll up");
      if (props.handleExitViewportScrollUp) {
        props.handleExitViewportScrollUp();
      }

    }
  };

  const clazz = ["waypoint-wrapper"];
  if (props.className) clazz.push(props.className);

  return (

    <Waypoint onEnter={handleWaypointEnter} onLeave={handleWaypointLeave} scrollableAncestor="window">
      <div className={clazz.join(" ")} style={props.style}>
        {props.children}
      </div>
    </Waypoint>

  );
};

export default WatchedElement;

import React from "react"

const Marquee = (props) => {
  const news = [
    "Around 50% of people will smile back if you smile at them",
    "Smiles are a universal sign of happiness",
    "It's physically easier to smile than it is to frown",
    "Humans can detect smiles from more than 300 feet away"
  ]
  return (
    <div className="marquee is-relative">
    <div className="marquee-1">
      {
        news.map((item, index) => (
            <p className="has-text-weight-bold is-inline" key={index}>&nbsp;•&nbsp;{item}</p>
        ))
      }
      </div>
      {/*
        Hack to make marquee continuous
      
      <div className="marquee-2">
        {
          news.map((item, index) => (
              <p className="has-text-weight-bold is-inline" key={index}>&nbsp;•&nbsp;{item}</p>
          ))
        }
        </div>
        */}

    </div>
  )

}

export default Marquee

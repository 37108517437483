import React, { Component, Fragment } from "react";
import { isBrowser } from "react-device-detect";
import Modal from "react-modal";
import anime from "animejs/lib/anime.es.js";
import AnimatedLines from "./animatedLines";
import { Link } from "gatsby";
import "./age-verification-modal.scss";
import TextField from "./form/textField";
import Rejoiner from "../lib/tracking/rejoiner";
import Justuno from "../lib/tracking/justuno";
import FacebookPixel from "../lib/tracking/facebook-pixel";
import Klayvio from "../lib/tracking/klayvio";
import axios from "axios";

import { validateEmail, validatePhone } from "../lib/helpers";

const customStyles = {
  overlay: {
    position: "fixed",
    zIndex: "999",
    backgroundColor: "transparent"
  }
};

export default class AgeVerificationModal extends Component {
  constructor (props) {
    super(props);

    this.state = {
      email: "",
      phone: "",
      error_phone: "",
      isOptedIn: false,
      inputCoupon: "WELCOME10"
    };
    this.afterOpenModal = this.afterOpenModal.bind(this);
  }

  copyToClipboard = (e) => {
    var couponInput = document.querySelector('.coupon-input');
    couponInput.select();
    document.execCommand('copy');
    this.setState({ inputCoupon: 'COPIED!' });
    setTimeout(() => {
      this.setState({ inputCoupon: 'WELCOME10' });
      couponInput.blur();
    }, 1200);
  };

  componentDidMount () {
  }

  afterOpenModal () {
    anime({
      targets: ".breezy-blob",
      d: [
        {
          value: "M582.358 146.992c40.028 126.68-65.169 361.37-191.511 401.504-126.345 40.134-343-3-383.03-129.679C-32.207 292.138 103.73 54.172 230.073 14.038c126.343-40.134 312.26 6.275 352.286 132.954"
        }
      ],
      duration: 6000,
      autoplay: true,
      easing: "linear",
      elasticity: 100,
      loop: true
    });
  }
  resetErrors = () => {
    this.setState({
      error_phone: ""
    });
  };
  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };
  handleModalClose() {
    localStorage.setItem("age_verified", JSON.stringify(true));
    this.props.closeModal();
  }
  handleSubscribe = async (e) => {
    e && e.preventDefault();
    this.resetErrors();
    let phone = validatePhone(this.state.phone);
    if(phone){
      //still let user proceed even if they don't enter email
      if (this.state.email.length === 0) {

      } else if (validateEmail(this.state.email)) {
        /*
        Rejoiner.subscribeToList({
          email: this.state.email,
          phone: phone,
          list_id: process.env.GATSBY_REJOINER_AGE_VERIFICATION_SUBSCRIBER_LIST_ID
        });
        */
        Klayvio.identify({
          '$email': this.state.email,
          '$phone_number': phone
        })
       
        const api_url = `https://smile-api-production.herokuapp.com/api/v1/smile-subscribe`
        const sms_api_url = `https://smile-api-production.herokuapp.com/api/v1/smile-sms-subscribe`
        const formData = { api_key: "pk_a67d8ed7894f8e1e56ada9eb616df05f7a",profiles: [{email: this.state.email, phone_number: phone}] }
        axios.post(sms_api_url, formData)
        axios.post(api_url, formData)
        FacebookPixel.track("CompleteRegistration"); // yes - is subscription event, read Facebook Pixel standard events docs
      } else {
        return;
      }

      localStorage.setItem("age_verified", JSON.stringify(true));
      //this.props.closeModal();
      this.setState({isOptedIn:true});
      Justuno.juapp('arb','email_captured',true);
    }else{
      this.setState({ error_phone: "Invalid phone number" });
    }
  };
  render () {
    return (
      <Fragment>

        <Modal
          isOpen={this.props.isModalOpen}
          onAfterOpen={this.afterOpenModal}
          style={customStyles}
          className="age-verification-modal"
          overlayClassName="age-verification-overlay"
        >
          <a onClick={() => this.handleModalClose()} className="close-modal"><i className="fal fa-times"></i></a>
          { this.state.isOptedIn?
          <div className="age-verification-modal--inner">

            <h3 class="h3-mobile">Use code <input value={this.state.inputCoupon} className="coupon-input" onFocus={this.copyToClipboard}></input> for</h3>
            <h1>10% off</h1>
            <h3 class="h3-mobile">on your next purchase</h3>

            <Link to="/products/cbd-oil-mighty-mango">
              <button className="button is-primary is-fullwidth shop-button">
                      SHOP NOW
              </button>
            </Link>
            <button className="button is-primary is-fullwidth is-secondary continue-button" onClick={() => this.handleModalClose()}>
              CONTINUE
            </button>
          </div>
          :
          <div className="age-verification-modal--inner">
            
          <AnimatedLines className="lines-03" style={{
            transform: "scaleX(1) rotate(400deg)",
            position: "absolute",
            top: isBrowser ? "45%" : "30%",
            left: isBrowser ? "-64%" : "-90%",
            zIndex: "0",
            width: "494px"
          }} />
          <AnimatedLines className="lines-04" style={{
            transform: "rotate(300deg)",
            position: "absolute",
            top: isBrowser ? "-10%" : "-10%",
            right: isBrowser ? "-50%" : "-80%",
            zIndex: "0",
            width: "454px"
          }} />
            <h3>Why hello there, enjoy</h3>
            <h1>10% off</h1>
            <h3>on your next purchase</h3>
            <br />
            <form onSubmit={this.handleSubscribe} className="age-verification-modal--form validate" noValidate>
              <div className="field is-horizontal">
                <div className="field-body">
                  <TextField label="Email" name="email" value={this.state.email} onChange={this.handleChange}/>
                  <TextField label="Phone" name="phone" error={this.state.error_phone} value={this.state.phone} onChange={this.handleChange}/>
                </div>
              </div>
              <div className="field">
                <div className="control">
                  <button type="submit" className="button is-primary is-normal is-fullwidth  animated-done-button" name="subscribe">
                    GET MY 10% OFF
                  </button>
                </div>
              </div>
              <div className="field">
                <div className="control">
                  <a onClick={() => this.handleModalClose()} className="is-outlined no-thanks is-inverted is-medium has-text-weight-bold">
                    NO, THANKS. 
                    <br/>I DON’T WANT 10% OFF
                  </a>
                  </div>
                  </div>

                  <span>*only valid in US</span>
            </form>
          </div>
        }  
        </Modal>
      </Fragment>

    );
  }
}

import React, { Fragment } from "react";
import { cartTotalReducer } from "../../lib/cart";
import { applyCouponToCartProducts } from "../../lib/coupon";
import CartTotalRowModal from "./cart-total-row-modal";
import { formatAmount } from "../../lib/helpers";

export default function CartTotal ({ cartProducts = [], coupon = null }) {

  const subscriptionProducts = cartProducts.filter(p => p.type === "subscription");
  let subTotal = subscriptionProducts.reduce((sum, product) => sum + parseFloat(product.price) * product.quantity, 0);
  const saveSum = subscriptionProducts.reduce((sum, product) => sum + parseFloat(product.oneOffPrice) * product.quantity, 0) - subTotal;
  if (coupon) {
    const {
      subtotal, // before discount
      discount_sum,
      discount_percent,
      total // after discount
    } = applyCouponToCartProducts(cartProducts, coupon);
    if (coupon.discount_type === "recurring_percent" || coupon.discount_type === "universal_recurring_percent") {
      const priceAfterCoupon = applyCouponToCartProducts(subscriptionProducts, coupon);
      subTotal = priceAfterCoupon.total;
    }
    let discount_text = `-$` + formatAmount(discount_sum);
    if (discount_percent) discount_text += " (" + discount_percent + "%)";

    return (
      <div className="price-details">
        {
         subscriptionProducts.length > 0 && <CartTotalRowModal title="Cost per bill">${formatAmount(subTotal)} / month</CartTotalRowModal>
        }
        { total > 50 &&
        <CartTotalRowModal title="1-3 Day Shipping (US Only)">Free</CartTotalRowModal>
        }
        <CartTotalRowModal title="Subtotal">${formatAmount(subtotal)}</CartTotalRowModal>
        {
         subscriptionProducts.length > 0 && <CartTotalRowModal title="Subscription savings" text="has-text-danger">${formatAmount(saveSum)} / month</CartTotalRowModal>
        }
        <CartTotalRowModal title="Discount" text="has-text-danger"><span className="has-text-danger">{discount_text}</span></CartTotalRowModal>
        <CartTotalRowModal title="Total due now" bold="true">${formatAmount(total)}</CartTotalRowModal>
      </div>
      
    );
  }

  // no coupon
  const total = cartProducts.reduce(cartTotalReducer, 0);
  return (
    <div className="price-details"> 
    {
         subscriptionProducts.length > 0 && <CartTotalRowModal title="Cost per bill">${formatAmount(subTotal)} / month</CartTotalRowModal>
        }
    { total > 50 &&    
    <CartTotalRowModal title="1-3 Day Shipping (US Only)">Free</CartTotalRowModal>
    }
    {
         subscriptionProducts.length > 0 && <CartTotalRowModal title="Subscription savings" text="has-text-danger">${formatAmount(saveSum)} / month</CartTotalRowModal>
        }
    <CartTotalRowModal title="Total due now" bold="true">${formatAmount(total)}</CartTotalRowModal>
    </div> 
  );
}

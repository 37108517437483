import { Link, navigate } from "gatsby";
import PropTypes from "prop-types";
import React, { Fragment } from "react";
import AlternateLogo from "../images/smile_logo.svg";
import Logo from "../images/smile_logo_yellow.svg";
import Modal from "./modal";
import LoginForm from "./account/loginForm";
import Accordion from "./accordion";
import anime from "animejs/lib/anime.es.js";
import AnimatedHorizontalDivider from "./animatedHorizontalDivider";
import HeaderCart from "./header-cart";
import { getCartQuantity } from "../lib/cart";
import auth from "../lib/auth";
import Justuno from "../lib/tracking/justuno";
import { validateEmail } from "../lib/helpers";
import Rejoiner from "../lib/tracking/rejoiner";
import axios from "axios";

class Header extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      isModalOpen: false,
      isLoginModalOpen: false,
      loginCallback: null,
      cartQuantity: 0,
      isAlternate: props.isAlternate,
      scrollY: 0,
      guestReview: false,
      email: ""
    };
  }

  componentDidMount = () => {
    this.setState({ cartQuantity: getCartQuantity() });
    window.addEventListener('scroll', this.handleScroll, { passive: true });
    anime({ targets: "#header-divider", width: "100%", easing: "easeInOutQuad", duration: 1000 });
  };

  componentWillUnmount = () => {
    window.removeEventListener('scroll', this.handleScroll)
  };

  handleScroll = (event) => {
    if(window.scrollY < this.state.scrollY){
      if(window.scrollY < 5){
        const header = document.getElementById("masthead");
        header.classList.remove("fixed-header");
        this.setState({ isAlternate: this.props.isAlternate });
      }else{
        const header = document.getElementById("masthead");
        header.classList.add("fixed-header");
        this.setState({ isAlternate: false });
      }
    }
    this.setState({ scrollY: window.scrollY });
  };

  handleModalOpen = () => {
    const newIsModalOpen = !this.state.isModalOpen;
    if (newIsModalOpen) {
      document.documentElement.classList.add("has-navbar-menu-active");
    } else {
      document.documentElement.classList.remove("has-navbar-menu-active");
    }
    this.setState({
      isModalOpen: newIsModalOpen
    });
  };

  handleModalClose = () => {
    if (this.state.isModalOpen) {
      this.setState({ isModalOpen: false });
      document.documentElement.classList.remove("has-navbar-menu-active");
    }
    if (this.state.isLoginModalOpen) {
      this.setState({ isLoginModalOpen: false });
    }
  };

  openLoginModal = (guestReview, loginCallback = null) => {
    this.setState({ isLoginModalOpen: true, guestReview: guestReview, loginCallback });
  };

  closeLoginModal = () => {
    this.setState({ isLoginModalOpen: false, loginCallback: null });
  };

  handleAccountPress = event => {
    event.preventDefault();
    if (auth.loggedIn) {
      navigate("/account");
    } else {
      this.openLoginModal(false, () => {
        navigate("/account");
      });
    }
  };

  handleLoginSuccess = () => {
    const loginCallback = this.state.loginCallback;
    this.closeLoginModal();
    loginCallback && loginCallback();
  };

  handleMouseEnter = () => {
    //this.setState({ isAlternate: false });
  };
  handleMouseLeave = () => {
    /*
    const fixedHeader = document.getElementsByClassName("fixed-header");
    
    if(fixedHeader.length === 0){
      this.setState({ isAlternate: this.props.isAlternate });
    }
    */
  };
  handleSubscribe = async (e) => {
    e && e.preventDefault();

    //still let user proceed even if they don't enter email
    if (this.state.email.length === 0) {

    } else if (validateEmail(this.state.email)) {
      /*
      Rejoiner.subscribeToList({
        email: this.state.email,
        list_id: process.env.GATSBY_REJOINER_AGE_VERIFICATION_SUBSCRIBER_LIST_ID
      });
      */
      const api_url = `https://smile-api-production.herokuapp.com/api/v1/smile-subscribe`
      const formData = { api_key: "pk_a67d8ed7894f8e1e56ada9eb616df05f7a",profiles: [{email: this.state.email}] }
      axios.post(api_url, formData)
     
    } else {
      return;
    }

    localStorage.setItem("age_verified", JSON.stringify(true));
    Justuno.juapp('arb','email_captured',true);
    this.handleModalOpen();
  };
  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render () {
    const navbarClass = [
      "navbar",
      this.state.isModalOpen ? "has-white-background" : "is-transparent",
      this.state.isAlternate ? "has-text-white" : "",
      this.state.isModalOpen ? "has-navbar-menu-active" : ""
    ].filter(cl => cl).join(" ");
    return (
      <Fragment>
        <header id="masthead" className="header">
          <div className="container">
            <nav className={navbarClass} role="navigation" aria-label="main navigation">
              <div className="navbar-brand is-hidden-tablet">
                <Link className="navbar-item navbar-logo" to="/" onClick={() => this.handleModalClose()}>
                  <img src={!this.state.isAlternate || this.state.isModalOpen
                    ? Logo
                    : AlternateLogo} width="112" height="28" alt="logo" />
                </Link>
                <a role="button" className={`navbar-burger burger ${this.state.isModalOpen ? "is-active" : ""}`} aria-label="menu" aria-expanded="false" onClick={() => this.handleModalOpen()} style={{
                  color: !this.state.isAlternate || this.state.isModalOpen
                    ? "black"
                    : "white"
                }}>
                  <span aria-hidden="true" />
                  <span aria-hidden="true" />
                  <span aria-hidden="true" />
                </a>
                <a onClick={(e) => this.handleAccountPress(e)} className="navbar-item header-account">
                  <img src={!this.state.isAlternate || this.state.isModalOpen
                    ? require("../images/user-alternate.svg")
                    : require("../images/user.svg")} alt="" />
                </a>
                <HeaderCart quantity={this.state.cartQuantity} isBlack={!this.state.isAlternate || this.state.isModalOpen} />
              </div>

              <div id="navbarBasicExample" className={this.state.isModalOpen
                ? "navbar-menu is-active"
                : "navbar-menu"}>

                <div className="navbar-start">
                  <div className="navbar-item has-dropdown is-hoverable is-hidden-mobile" onMouseEnter={this.handleMouseEnter} onMouseLeave={this.handleMouseLeave}>
                    <span className="navbar-link is-arrowless has-text-weight-bold has-text-centered">
                      Shop
                    </span>

                    <div className="navbar-dropdown shop-dropdown">
                      <div className="container">
                        <div className="columns is-centered">
                          <div className="column is-6 shop-by">
                            <h4>SHOP BY PRODUCT</h4>
                            <Link
                              to="/shop/rejuvenate"
                              style={{ textDecoration: "none" }}>
                              <br />
                              <div className="columns">
                                <div className="column is-narrow">
                                  <img
                                    src={require(`../images/menu/Topicals.svg`)}
                                    className="is-inline is-vertical-align"
                                    alt=""
                                  />
                                </div>
                                <div className="column">
                                  <h4 className="shop-by-title">Topicals</h4>
                                  <p
                                    className="is-hidden-mobile has-text-black shop-by-desp"
                                    style={{ marginTop: "5px" }}>
                                    Our luxuriously rich, fast-acting formula is supercharged with organic CBD for a renewed glow and instant relief.
                                  </p>
                                </div>
                              </div>
                            </Link>
                            <Link to="/shop/unwind" style={{ textDecoration: "none" }}>
                              <br/>
                              <div className="columns">
                                <div className="column is-narrow">
                                  <img src={require(`../images/menu/tinctures.svg`)} className="is-inline is-vertical-align" alt=""/>
                                </div>
                                <div className="column">
                                  <h4 className="shop-by-title">Oil Drops</h4>
                                  <p className="is-hidden-mobile has-text-black shop-by-desp" style={{marginTop:"5px"}}>Our potent combination of CBD oil and select nutrients provide improved focus or peace of mind.
                                  </p>
                                </div>
                              </div>
                            </Link>
                            <Link to="/shop/balance" style={{ textDecoration: "none" }}>
                              <br/>
                              <div className="columns">
                                <div className="column is-narrow">
                                  <img src={require(`../images/menu/gummies.svg`)} className="is-inline is-vertical-align" alt="" style={{marginTop:"10px"}}/>
                                </div>
                                <div className="column">
                                  <h4 className="shop-by-title">Gummies</h4>
                                  <p className="is-hidden-mobile has-text-black shop-by-desp" style={{marginTop:"5px"}}>Our blend of CBD and 9 powerhouse nutrients, best used for sustained energy and a balanced mood.
                                  </p>
                                </div>
                              </div>
                            </Link>
                          </div>
                          <div className="column is-6 shop-by" style={{borderLeft: 'solid 1px #3D3935'}}>
                            <h4>SHOP BY EFFECT</h4>
                            <Link to="/shop/energize" style={{ textDecoration: "none" }}>
                              <br/>
                              <div className="columns">
                                <div className="column is-narrow" style={{marginLeft:'-8px'}}>
                                  <img src={require(`../images/menu/energize.svg`)} className="is-inline is-vertical-align" alt=""/>
                                </div>
                                <div className="column" style={{marginLeft:'-8px'}}>
                                  <h4 className="shop-by-title">Energize</h4>
                                  <p className="is-hidden-mobile has-text-black shop-by-desp" style={{marginTop:"5px"}}>Shop Energize for Energy and Focus
                                  </p>
                                </div>
                              </div>
                            </Link>
                            <Link to="/shop/unwind" style={{ textDecoration: "none" }}>
                              <br/>
                              <div className="columns">
                                <div className="column is-narrow">
                                  <img src={require(`../images/menu/unwind.svg`)} className="is-inline is-vertical-align" alt="" style={{marginTop:"5px"}}/>
                                </div>
                                <div className="column">
                                  <h4 className="shop-by-title">Unwind</h4>
                                  <p className="is-hidden-mobile has-text-black shop-by-desp" style={{marginTop:"5px"}}>Shop Unwind for Relaxation and Sleep
                                  </p>
                                </div>
                              </div>
                            </Link>
                            <Link to="/shop/balance" style={{ textDecoration: "none" }}>
                              <br/>
                              <div className="columns">
                                <div className="column is-narrow">
                                  <img src={require(`../images/menu/balance.svg`)} className="is-inline is-vertical-align" alt="" style={{marginTop:"5px"}}/>
                                </div>
                                <div className="column">
                                  <h4 className="shop-by-title">Balance</h4>
                                  <p className="is-hidden-mobile has-text-black shop-by-desp" style={{marginTop:"5px"}}>Shop Balance for a Balanced Mood
                                  </p>
                                </div>
                              </div>
                            </Link>
                            <Link
                              to="/shop/rejuvenate"
                              style={{ textDecoration: "none" }}>
                              <br />
                              <div className="columns">
                                <div className="column is-narrow">
                                  <img
                                    src={require(`../images/menu/rejuvenate.svg`)}
                                    className="is-inline is-vertical-align"
                                    alt=""
                                    style={{ marginTop: "5px" }}
                                  />
                                </div>
                                <div className="column">
                                  <h4 className="shop-by-title">Rejuvenate</h4>
                                  <p
                                    className="is-hidden-mobile has-text-black shop-by-desp"
                                    style={{ marginTop: "5px" }}>
                                    Shop Rejuvenate for Smooth Skin and Pain Relief
                                  </p>
                                </div>
                              </div>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="navbar-item has-dropdown is-hoverable is-hidden-mobile" onMouseEnter={this.handleMouseEnter} onMouseLeave={this.handleMouseLeave}>
                    <a className="navbar-link is-arrowless has-text-weight-bold has-text-centered">
                      Learn
                    </a>

                    <div className="navbar-dropdown learn-dropdown">
                      <div className="container">
                        <div className="columns is-centered has-text-centered">
                          <div className="column is-3 learn-by">
                            <Link to="/ingredients" style={{ textDecoration: "none" }}>
                              <h4 className="learn-by-title">Ingredients</h4>
                              <p className="is-hidden-mobile has-text-black" style={{marginTop:"10px"}}>Learn more about what's in our products.
                              </p>
                            </Link>
                          </div>

                          <div className="column is-3 learn-by">
                            <Link to="/story" style={{ textDecoration: "none" }}>
                              <h4 className="learn-by-title">Our Story</h4>
                              <p className="is-hidden-mobile has-text-black" style={{marginTop:"10px"}}>Learn more about why we do, what we do.
                              </p>
                            </Link>
                          </div>
                          <div className="column is-3 learn-by">
                            <Link to="/news" style={{ textDecoration: "none" }}>
                              <h4 className="learn-by-title">Smile News</h4>
                              <p className="is-hidden-mobile has-text-black" style={{marginTop:"10px"}}>Learn more about nutrition, science and well-being.
                              </p>
                            </Link>
                          </div>
                          <div className="column is-3 learn-by">
                            <Link to="/faq" style={{ textDecoration: "none" }}>
                              <h4 className="learn-by-title">FAQ</h4>
                              <p className="is-hidden-mobile has-text-black" style={{marginTop:"10px"}}>Have a question? We have an answer.
                              </p>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="is-hidden-tablet mobile-header-dropdown">
                  <Accordion className="shop-by-accordion"
                      data={[
                        {
                          title: <h4>SHOP BY PRODUCT</h4>,
                          content: <div>
                            <div className="shop-by-item">
                              <a onClick={(e) => {this.handleModalOpen(); navigate("/shop/rejuvenate"); }} style={{ textDecoration: "none" }}>
                              <img src={require(`../images/menu/Topicals.svg`)} className="is-inline is-vertical-align" alt=""  style={{marginLeft:'2px'}}/>
                              <h4 className="shop-by-title is-vertical-align">Topicals</h4>
                              </a>
                            </div>
                            <div className="shop-by-item">
                              <a onClick={(e) => {this.handleModalOpen(); navigate("/shop/unwind"); }} style={{ textDecoration: "none" }}>
                              <br />
                              <img src={require(`../images/menu/tinctures.svg`)} className="is-inline is-vertical-align" alt=""/>
                              <h4 className="shop-by-title is-vertical-align">Oil Drops</h4>
                              </a>
                            </div>
                            <div className="shop-by-item">
                              <a onClick={(e) => {this.handleModalOpen(); navigate("/shop/balance"); }} style={{ textDecoration: "none" }}>
                                <br/>
                                <img src={require(`../images/menu/gummies.svg`)} className="is-inline is-vertical-align" alt=""/>
                                <h4 className="shop-by-title is-vertical-align">Gummies</h4>
                              </a>
                            </div>
                          </div>
                        },
                        {
                          title: <h4>SHOP BY EFFECT</h4>,
                          content: <div>
                            <div className="shop-by-item">
                              <a onClick={(e) => {this.handleModalOpen(); navigate("/shop/energize"); }} style={{ textDecoration: "none" }}>
                                <img src={require(`../images/menu/energize.svg`)} className="is-inline is-vertical-align" alt="" style={{marginLeft: '-9px'}}/>
                                <h4 className="shop-by-title is-vertical-align" style={{marginLeft: '15px'}}>Energize</h4>
                              </a>
                            </div>
                            <div className="shop-by-item">
                              <a onClick={(e) => {this.handleModalOpen(); navigate("/shop/unwind"); }} style={{ textDecoration: "none" }}>
                                <br/>
                                <img src={require(`../images/menu/unwind.svg`)} className="is-inline is-vertical-align" alt=""/>
                                <h4 className="shop-by-title is-vertical-align" style={{marginLeft: '23px'}}>Unwind</h4>
                              </a>
                            </div>
                            <div className="shop-by-item">
                              <a onClick={(e) => {this.handleModalOpen(); navigate("/shop/balance"); }} style={{ textDecoration: "none" }}>
                                <br/>
                                <img src={require(`../images/menu/balance.svg`)} className="is-inline is-vertical-align" alt=""/>
                                <h4 className="shop-by-title is-vertical-align" style={{marginLeft: '22px'}}>Balance</h4>
                              </a>
                            </div>
                            <div className="shop-by-item">
                              <a onClick={(e) => {this.handleModalOpen(); navigate("/shop/rejuvenate"); }} style={{ textDecoration: "none" }}>
                                <br/>
                                <img src={require(`../images/menu/rejuvenate.svg`)} className="is-inline is-vertical-align" alt=""/>
                                <h4 className="shop-by-title is-vertical-align" style={{marginLeft: '22px'}}>Rejuvenate</h4>
                              </a>
                            </div>
                          </div>
                        },
                        {
                          title: <h4>LEARN</h4>,
                          content: <div className="learn-by">
                            <a onClick={(e) => {this.handleModalOpen(); navigate("/faq"); }} style={{ textDecoration: "none" }}>
                              <h4 className="learn-by-title">FAQ</h4>
                            </a>
                            <a onClick={(e) => {this.handleModalOpen(); navigate("/ingredients"); }} style={{ textDecoration: "none" }}>
                              <br/>
                              <h4 className="learn-by-title">Ingredients</h4>
                            </a>
                            <a onClick={(e) => {this.handleModalOpen(); navigate("/news"); }} style={{ textDecoration: "none" }}>
                              <br/>
                              <h4 className="learn-by-title">Smile News</h4>
                            </a>
                            <a onClick={(e) => {this.handleModalOpen(); navigate("/story"); }} style={{ textDecoration: "none" }}>
                              <br/>
                              <h4 className="learn-by-title">Our Story</h4>
                            </a>
                          </div>
                        }
                      ]}
                      openedKeys={[0,1]}
                    />
                   <form style={{marginTop:'40px'}} onSubmit={this.handleSubscribe}>
                   <div className="field">
                    <div className="control">
                      <input type="email" className="input" placeholder="Your e-mail address" name="email" value={this.state.email} onChange={this.handleChange} required />
                    </div>
                  </div>
                  <div className="field">
                    <div className="control">
                      <button type="submit" className="button is-primary is-normal is-fullwidth  animated-done-button" name="subscribe">
                        GET MY 10% OFF
                      </button>
                    </div>
                  </div>
                   </form>
                  </div>
                </div>

                <div className="navbar-brand is-hidden-mobile navbar-brand-desktop">
                  <Link className="navbar-item" to="/" onClick={() => this.handleModalClose()}>
                    <img src={this.state.isAlternate
                      ? AlternateLogo
                      : Logo} width="112" height="28" alt="logo" />
                  </Link>

                  <a role="button" className="navbar-burger burger" aria-label="menu" aria-expanded="false" onClick={() => this.handleModalOpen()}>
                    <span aria-hidden="true" />
                    <span aria-hidden="true" />
                    <span aria-hidden="true" />
                  </a>
                </div>

                <div className="navbar-end is-hidden-mobile">
                  <a onClick={(e) => this.handleAccountPress(e)} className="navbar-item">
                    <img src={this.state.isAlternate
                      ? require("../images/user.svg")
                      : require("../images/user-alternate.svg")} alt="" />
                  </a>
                  <HeaderCart quantity={this.state.cartQuantity} isBlack={!this.state.isAlternate} />
                </div>

              </div>

            </nav>
            <AnimatedHorizontalDivider id="header-divider" color={!this.state.isAlternate || this.state.isModalOpen
              ? "black"
              : "white"} />

          </div>
        </header>
        <Modal isModalOpen={this.state.isLoginModalOpen} closeModal={this.handleModalClose}>
          <LoginForm handleLoginSuccess={this.handleLoginSuccess} guestReview={this.state.guestReview}/>
        </Modal>
      </Fragment>

    );
  }

}

Header.propTypes = {
  siteTitle: PropTypes.string
};

Header.defaultProps = {
  siteTitle: ``
};

export default Header;

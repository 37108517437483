import React, { Component } from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";
import Logo from "../images/smile_logo.svg";
import Marquee from "../components/marquee";
import AnimatedUnderline from "../components/animatedUnderline";
import Rejoiner from "../lib/tracking/rejoiner";
import FacebookPixel from "../lib/tracking/facebook-pixel";
import Klayvio from "../lib/tracking/klayvio";
import { validateEmail } from "../lib/helpers";
import axios from "axios";

class Footer extends Component {

  constructor (props) {
    super(props);
    this.state = {
      email: "",
      subscribed: false
    };
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubscribe = e => {
    e.preventDefault();

    if (this.state.email && validateEmail(this.state.email)) {
      /*
      Rejoiner.subscribeToList({
        email: this.state.email,
        list_id: "9kBLn5m" // todo to env
      });
      */
      Klayvio.identify({
        '$email': this.state.email
      });
      FacebookPixel.track("CompleteRegistration"); // yes - is subscription event, read Facebook Pixel standard events docs

      const api_url = `https://smile-api-production.herokuapp.com/api/v1/smile-subscribe`
      const formData = { api_key: "pk_a67d8ed7894f8e1e56ada9eb616df05f7a",profiles: [{email: this.state.email}] }
      axios.post(api_url, formData)
      // localStorage.setItem("offers_subscribed", JSON.stringify(true)); // todo need hide sub form on next visit if already subscribed?
      this.setState({ subscribed: true }); // hide form in current visit
    }
  };

  render () {
    return (
      <>
        <Marquee />
        <footer className="footer">
          <img className="footer-blob footer-blob-1" src={require("../images/blob-01.svg")} alt="" />
          <img className="footer-blob footer-blob-2" src={require("../images/blob-02.svg")} alt="" />

          <div className="container">

            <div className="columns is-mobile is-multiline">
              <div className="column is-5-desktop is-5-tablet is-12-mobile">
                <img className="footer-logo" src={Logo} alt="" />
                <h4 className="has-text-light">Limited offers, new products, only things you need to know. Delivered right to your inbox</h4>
                <br />
                {this.state.subscribed ?
                  <p className="has-text-white has-text-weight-bold	is-uppercase">
                    You successfully subscribed
                  </p>
                  :
                  <form className="validate is-inline-block footer-subscribe-form" noValidate onSubmit={this.handleSubscribe}>
                    <div className="field has-addons">
                      <div className="control">
                        <input type="email" className="input is-borderless" placeholder="Enter email" name="email" value={this.state.email} onChange={this.handleChange} required />
                      </div>
                      <div className="control">
                        <button type="submit" className="button is-white is-outlined is-medium has-text-weight-bold" name="subscribe">
                          OK
                        </button>
                      </div>
                    </div>
                  </form>
                }
              </div>

              <div className="column is-offset-1-desktop is-3-mobile">
                <ul>
                  <li>
                    <AnimatedUnderline>
                      <Link to="/shop/rejuvenate" className="footer-link">Topicals</Link>
                    </AnimatedUnderline>
                  </li>
                  <li>
                    <AnimatedUnderline>
                      <Link to="/shop/unwind" className="footer-link">Oil Drops</Link>
                    </AnimatedUnderline>
                  </li>
                  <li>
                    <AnimatedUnderline>
                      <Link to="/shop/balance" className="footer-link">Gummies</Link>
                    </AnimatedUnderline>
                  </li>
                </ul>
              </div>
              <div className="column is-3-mobile">
                <ul>
                  <li>
                    <AnimatedUnderline>
                      <Link to="/shop/energize" className="footer-link">Energize</Link>
                    </AnimatedUnderline>
                  </li>
                  <li>
                    <AnimatedUnderline>
                      <Link to="/shop/unwind" className="footer-link">Unwind</Link>
                    </AnimatedUnderline>
                  </li>
                  <li>
                    <AnimatedUnderline>
                      <Link to="/shop/balance" className="footer-link">Balance</Link>
                    </AnimatedUnderline>
                  </li>
                  <li>
                    <AnimatedUnderline>
                      <Link to="/shop/rejuvenate" className="footer-link">Rejuvenate</Link>
                    </AnimatedUnderline>
                  </li>
                </ul>

              </div>
              <div className="column is-4-mobile">
                <ul>

                  <li>
                    <AnimatedUnderline>
                      <Link to="/story" className="footer-link">Our Story</Link>
                    </AnimatedUnderline>
                  </li>

                  <li>
                    <AnimatedUnderline>
                      <Link to="/ingredients" className="footer-link">Ingredients</Link>
                    </AnimatedUnderline>
                  </li>


                  <li>
                    <AnimatedUnderline>
                      <Link to="/news" className="footer-link">Smile News</Link>
                    </AnimatedUnderline>
                  </li>

                  <li>
                    <AnimatedUnderline>
                      <Link to="/faq" className="footer-link">FAQ</Link>
                    </AnimatedUnderline>
                  </li>

                  <li>
                    <AnimatedUnderline>
                      <Link to="/wholesale" className="footer-link">Wholesale</Link>
                    </AnimatedUnderline>
                  </li>
                  <li>
                    <AnimatedUnderline>
                      <Link to="/faq" className="footer-link">Contact Us</Link>
                    </AnimatedUnderline>
                  </li>
                </ul>
              </div>
              <div className="column is-2-mobile">
                <a target="_blank" href="https://instagram.com/smile" rel="noopener noreferrer">
                  <i className="fab fa-instagram fa-2x has-text-white" />
                </a>&nbsp;&nbsp;&nbsp;&nbsp;
                <a target="_blank" href="https://facebook.com/smile" rel="noopener noreferrer">
                  <i className="fab fa-facebook fa-2x has-text-white" />
                </a>
              </div>
            </div>
            <div className="columns is-multiline">
              <div className="column is-5">
                <p className="has-text-white has-text-weight-bold">@{(new Date().getFullYear())} Reason To Smile. All rights reserved.</p>
              </div>
              <div className="column is-8">
                <ul className="horizontal-footer-links">
                  <li className="is-inline">
                    <AnimatedUnderline>
                      <Link to="/returns" className="has-text-white">Returns and Refunds</Link>
                    </AnimatedUnderline>
                  </li>
                  <li className="is-inline">
                    <AnimatedUnderline>
                      <Link to="/privacy" className="has-text-white">Privacy Policy</Link>
                    </AnimatedUnderline>
                  </li>
                  <li className="is-inline">
                    <AnimatedUnderline>
                      <Link to="/terms" className="has-text-white">Terms of Service</Link>
                    </AnimatedUnderline>
                  </li>
                  <li className="is-inline">
                    <AnimatedUnderline>
                      <Link to="/authenticity" className="has-text-white">Certificate of Authenticity</Link>
                    </AnimatedUnderline>
                  </li>
                </ul>
              </div>
            </div>
            <hr />
            <div className="columns">
              <div className="column is-4">
                <img data-src={require("../images/accepted-cards.svg")} alt="" className="lazyload" />
              </div>
              <div className="column is-8">
                <p className="has-text-white has-text-weight-bold footer-note">* The statements made regarding these products have not been evaluated by the Food and Drug Administration. The efficacy of these products has not been confirmed by FDA-approved research. These products are not intended to diagnose, treat, cure or prevent any disease. All information presented here is not meant as a substitute for or alternative to information from health care practitioners. Please consult your health care professional about potential interactions or other possible complications before using any product. The Federal Food, Drug, and Cosmetic Act require this notice.</p>
              </div>
            </div>
          </div>

        </footer>
      </>
    );
  }

}

Footer.propTypes = {
  siteTitle: PropTypes.string
};

Footer.defaultProps = {
  siteTitle: ``
};

export default Footer;

import React, { Component, createRef, Fragment } from "react";
import PropTypes from "prop-types";
import CartTotalModal from "./cart-total-modal";
import "./cart-discount-code.scss";
import {
    deleteLocalCouponCode,
    isValidCoupon,
    readLocalCouponCode,
    saveLocalCouponCode,
  } from "../../lib/coupon"

const initialState = () => ({
  discountCode: null,
  discountError: null,
  discountInfo: null,
  expanded: false,
  buttonLoading: false,
  coupon: null
});

export default class CartDiscountCodeModal extends Component {

  state = initialState();
  codeInput = createRef();

  addButtonClick = () => {
    this.setState({ expanded: true }, () => {
      this.codeInput.current.focus();
    });
  };

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleDiscountSubmit = async (e) => {
    e.preventDefault();
    try {
      this.setState({ buttonLoading: true });
      const { valid, error, coupon } = await isValidCoupon(this.state.discountCode, this.props.cartProducts);

      // coupon invalid
      if (!valid) {
        this.setState({ discountError: error, coupon: null });
        this.saveCoupon(null);
        return;
      }

      this.setState({ discountError: null, coupon });
      this.saveCoupon(coupon);
    } finally {
      this.setState({ buttonLoading: false });
    }
  };

  renderCouponInfo () {
    const coupon = this.state.coupon;

    if (coupon) {
      switch (coupon.discount_type) {
        case "percent":
        case "recurring_percent":
        case "universal_recurring_percent":
          return `<code>${coupon.code}</code>&nbsp;-${parseFloat(coupon.amount)}%`;
        case "fixed_cart":
          return `<code>${coupon.code}</code>&nbsp;-$${parseFloat(coupon.amount)}`;
        // other WooCommerce discount types
        // ...
        // ...
        default:
          return null;
      }
    }

    return null;
  }

  cancelCoupon = () => {
    this.setState(initialState());
    this.saveCoupon(null);
  };

  componentDidMount() {
    
    this.loadLocalCoupon() // promise
    
  }
  
  loadLocalCoupon = async () => {
    let couponCode = readLocalCouponCode()
    if (couponCode) {
      const { valid, coupon } = await isValidCoupon(couponCode)
      if (valid) {
        this.setState({
          discountCode: coupon.code,
          expanded: true,
          coupon,
        })
      } else {
        deleteLocalCouponCode()
      }
    }
  }
  saveCoupon = coupon => {
    if (coupon) {
      saveLocalCouponCode(coupon.code)
    } else {
      deleteLocalCouponCode()
    }
  }

  render () {
    return (
      <div className="column cart-discount-form" style={{padding:"0.5rem 0 0 0"}}>
      {this.state.expanded ?
        <div className="discount-code cart-discount-code">
          {this.state.coupon ?
            <Fragment>
              <div>
                <span dangerouslySetInnerHTML={{ __html: this.renderCouponInfo() }} />&nbsp;&nbsp;&nbsp;
                <button type="button" onClick={this.cancelCoupon} className="select-button has-text-warning">CANCEL</button>
              </div>
              {this.state.coupon.discount_type === "recurring_percent" &&
              <div className="notification is-info is-light discount-code-notification is-inline-block">
                <i className="fas fa-info-circle" />&nbsp;&nbsp;
                Recurring discount applied only for subscriptions products
              </div>
              }
            </Fragment>

            :
            <form onSubmit={this.handleDiscountSubmit}>
              <div className="field has-addons">
                <div className="control">
                  <input type="text" className="input is-primary is-outlined is-borderless"
                    placeholder="Enter discount code" onChange={this.handleChange} name="discountCode"
                    required
                    autoComplete="off"
                    ref={this.codeInput} />
                </div>
                <div className="control">
                  <button type="submit" className={`button is-primary is-outlined is-medium has-text-weight-bold${this.state.buttonLoading ? " is-loading" : ""}`}>
                    OK
                  </button>
                </div>
              </div>
              {this.state.discountError &&
              <p className="has-text-danger">{this.state.discountError}</p>
              }
            </form>
          }

        </div>
        :
        <button onClick={this.addButtonClick} className="select-button is-primary" style={{fontSize:'12px'}}>+ DISCOUNT CODE</button>
        }
        <CartTotalModal
                      cartProducts={this.props.cartProducts}
                      coupon={this.state.coupon}
                    />
        </div>  
    );
  }
}

CartDiscountCodeModal.propTypes = {
  cartProducts: PropTypes.arrayOf(PropTypes.object).isRequired
};

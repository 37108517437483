import React from "react";
import Modal from "react-modal";

export default function SmileModal (
  {
    isModalOpen = false,
    afterOpenModal = () => {},
    closeModal = () => {},
    children
  }
) {
  return (
    <Modal
      isOpen={isModalOpen}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      className="smile-modal"
      overlayClassName="smile-modal--overlay">
      {children}
      <button type="button" className="smile-modal--close" onClick={closeModal} aria-label="close" />
    </Modal>
  );
}

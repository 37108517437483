import React, { Component } from "react";
import "./accordion.scss";
import update from "immutability-helper";

class AccordionItem extends Component {
  render () {
    return (
      <li className={`accordion-list__item ${this.props.className || ""}`}>
        <div className={`accordion-item ${this.props.opened ? "accordion-item--opened" : ""}`}>
          <div className="accordion-item__line" onClick={this.props.onToggleClick}>
            <div className="accordion-item__title">{this.props.title}</div>
            <span className="accordion-item__icon" />
          </div>
          <div className="accordion-item__inner">
            <div className="accordion-item__content">
              <div className="accordion-item__paragraph">
                {this.props.content}
              </div>
            </div>
          </div>
        </div>
      </li>
    );
  }
}

export default class Accordion extends Component {

  constructor (props) {
    super(props);
    this.state = {
      openedKeys: this.props.openedKeys?this.props.openedKeys:[],
    };
  }

  render () {
    return (
      <div className={`accordion-wrapper ${this.props.className || ""} ${this.state.openedKeys.length ? "has-opened" : ""}`}>
        <ul className="accordion-list">
          {this.props.data.map((data, key) => {
            return (
              <AccordionItem
                key={key}
                className={data.className || ""}
                title={data.title}
                content={data.content}
                opened={this.state.openedKeys.includes(key)}
                onToggleClick={() => this.toggleItem(key)}
              />
            );
          })}
        </ul>
      </div>
    );
  }

  toggleItem = (key) => {
    const isOpened = this.state.openedKeys.includes(key);
    if (isOpened) {
      this.closeItem(key);
    } else {
      this.openItem(key);
    }
  };

  openItem = (key) => {
    const includes = this.state.openedKeys.includes(key);
    if (!includes) {
      const openedKeys = update(this.state.openedKeys, { $push: [key] });
      this.setState({ openedKeys });
      this.props.onItemOpened && this.props.onItemOpened(key);
    }
  };

  closeItem = (key) => {
    const index = this.state.openedKeys.findIndex(k => k === key);
    if (~index) {
      const openedKeys = update(this.state.openedKeys, { $splice: [[index, 1]] });
      this.setState({ openedKeys });

      this.props.onItemClosed && this.props.onItemClosed(key);
    }
  };
}

/**
 * This component used in two layouts:
 *  - layout.js
 *  - layout-fullpage.js
 */

import React, { Component, Fragment } from "react";
import AgeVerificationModal from "./age-verification-modal";
import CartModal from "./cart-modal";
import Modal from "react-modal";

Modal.setAppElement("#___gatsby");

export default class GlobalModals extends Component {

  constructor (props) {
    super(props);
    this.state = {
      showAgeVerificationModal: false
    };
  }

  componentDidMount () {
    let ageVerified = false;
    if (typeof (Storage) !== "undefined") {
      ageVerified = JSON.parse(localStorage.getItem("age_verified"));
    }

    // dont show age verification modal in PageSpeed Insights
    const regex = RegExp("Chrome-Lighthouse", "ig");
    const isPageSpeedBot = regex.test(navigator.userAgent);

    if (!ageVerified && !isPageSpeedBot) {
      setTimeout(() => {
        this.setState({ showAgeVerificationModal: true });
        }, 12500);
      
    }
  }

  render () {
    return (
      <Fragment>
        <AgeVerificationModal isModalOpen={this.state.showAgeVerificationModal}
          closeModal={() => this.setState({ showAgeVerificationModal: false })} />
        <CartModal isCartModalOpen={this.props.isCartModalOpen} handleCartModalClose={this.props.handleCartModalClose} newCartItem={this.props.newCartItem} localCart={this.props.localCart}/>
      </Fragment>
    );
  }

}

import React, { Component, Fragment } from "react"
import { navigate} from "gatsby"
import CartDiscountCodeModal from "./cart/cart-discount-code-modal"
import AcceptedPaymentMethods from "./acceptedPaymentMethods"
import { renderTags, renderProductName, renderProductDosage } from "../utilities/product"
import Modal from "react-modal"
import "./cart-modal.scss"

const customStyles = {
  overlay: {
    zIndex: "999",
    backgroundColor: "rgb(246, 189, 23, 0.75)",
  },
}

export default class CartModal extends Component {
  constructor(props) {
    super(props)

    this.state = {
      products: []
    }
  }

  componentDidMount() {
    
  }
  
  


  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value })
  }
  handleLink(link){
    this.props.handleCartModalClose();
    navigate(link); 
  }

  render() {
    // check exists
    const cartProducts = this.props.localCart
    var product = null
    var quantity = 0
    if (this.props.newCartItem != null && this.props.isCartModalOpen) {
      product = cartProducts.find(p => p.id === this.props.newCartItem.id);
      cartProducts.forEach(item => {
        quantity = quantity + item.quantity
      })
    }
    return (
      <Fragment>
        <Modal
          isOpen={this.props.isCartModalOpen}
          onAfterOpen={this.afterOpenModal}
          style={customStyles}
          className="cart-modal"
        >
          <a
            onClick={() => this.props.handleCartModalClose()}
            className="close-modal"
          >
            <i className="fal fa-times"></i>
          </a>
          <div className="cart-modal--inner">
            <div className="add-to-bag">
              <h2>Added to Bag</h2>
              <div onClick={() => this.handleLink("/cart")} className="navbar-item header-cart">
                <span className="added-circle" />
                <img src={require("../images/bag-alternate.svg")} alt="" />
                <p className="header-cart-count" style={{ color: "white" }}>{quantity}</p>
              </div>
            </div>
            {product != null ? (
              <div>
                <div className="columns">
                  <div className="column new-cart-item is-6">
                    <div className="product-image">
                    <figure className="image product-row-image">
                      {product.images &&
                        product.images[0] &&
                        product.images[0].src && (
                          <img src={product.images[0].src} alt="" />
                        )}
                    </figure>
                    </div>
                    <div className="product-info">
                      <p className="is-size-4">{renderProductName(product)}</p>
                      <p>{renderProductDosage(product)}</p>
                      <p>{renderTags(product)}</p>
                      <p>QTY: {product.quantity}</p>
                      <p className="product-price">
                        {product.type === "subscription"
                          ? `$${product.price} / month`
                          : `$${product.price}`
                        }   
                      </p>
                        
                    </div>
                  </div>
                  <div className="column is-6">
                    <div className="cart-benefits">
                      <div>
                        <img
                          src={require("../images/bag/free-shipping-yellow.svg")}
                          className="is-inline is-vertical-align"
                          alt=""
                        />
                        &nbsp;&nbsp;
                        <h4 className="is-inline is-vertical-align">
                          1-3 Day Shipping
                        </h4>
                      </div>
                      <div>
                        <img
                          src={require("../images/bag/cancel-anytime-yellow.svg")}
                          className="is-inline is-vertical-align"
                          alt=""
                        />
                        &nbsp;&nbsp;
                        <h4 className="is-inline is-vertical-align">
                          Cancel Anytime
                        </h4>
                      </div>
                      <div>
                        <img
                          src={require("../images/bag/get-a-refund-yellow.svg")}
                          className="is-inline is-vertical-align"
                          alt=""
                        />
                        &nbsp;&nbsp;
                        <h4 className="is-inline is-vertical-align">
                          100% Satisfaction Guaranteed
                        </h4>
                      </div>
                    </div>
                    <CartDiscountCodeModal
                      cartProducts={cartProducts}
                    />
                  
                  </div>
                </div>
                <div className="columns">
                  <div className="column is-6">
                  
                      <button
                        className="button is-medium is-secondary is-fullwidth"
                        type="submit"
                        onClick={() => this.handleLink("/cart")}
                      >
                        VIEW OR EDIT BAG ({quantity})
                      </button>
                   
                  </div>
                  <div className="column is-6">
                   
                      <button className="button is-medium is-primary is-fullwidth checkout-button" onClick={() => this.handleLink("/checkout")}>
                        CHECKOUT
                      </button>
                  </div>
                </div>
                <div className="columns is-reversed-mobile">
                  <div className="column is-6">
                    <span
                      onClick={() => this.props.handleCartModalClose()}
                      className="continue-shopping"
                    >
                      <i className="fal fa-chevron-left"></i>
                      CONTINUE SHOPPING
                    </span>
                  </div>
                  <div className="column is-6">
                    <AcceptedPaymentMethods />
                  </div>
                </div>
              </div>
            ) : (
              false
            )}
          </div>
        </Modal>
      </Fragment>
    )
  }
}

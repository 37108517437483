export const renderProductName = (product) => {
  let name;
  if ( !product ) { return; }
  if (product.slug.indexOf("gummies") !== -1) {
    name = "CBD Vegan Gummies";
  }else if (product.slug.indexOf("face-cream") !== -1){
    name = "CBD Face Cream";
  }else if (product.slug.indexOf("body-lotion") !== -1){
    name = "CBD Body Lotion";
  }else if (product.slug.indexOf("pain-cream") !== -1){
    name = "CBD Pain Cream";
  }else {
    name = "CBD Oil";
  }
  return name;
}
export const renderProductNamewithDosage = (product) => {
  let name;
  if ( !product ) { return; }
  if (product.slug.indexOf("gummies") !== -1) {
    name = "CBD Vegan Gummies: 300MG";
  }else if (product.slug.indexOf("face-cream") !== -1){
    name = "CBD Face Cream: 300MG";
  }else if (product.slug.indexOf("body-lotion") !== -1){
    name = "CBD Body Lotion: 500MG";
  }else if (product.slug.indexOf("pain-cream") !== -1){
    name = "CBD Pain Cream: 500MG";
  }else if(product.slug.indexOf("1000") !== -1) {
    name = "CBD Oil: 1000MG";
  }else {
    name = "CBD Oil: 500MG";
  }
  return name;
}
export const renderProductDosage = (product) => {
  let name;
  if ( !product ) { return; }
  if (product.slug.indexOf("vegan-gummies") !== -1 || product.slug.indexOf("face-cream") !== -1) {
    name = "300MG";
  }else if (product.slug.indexOf("topicals") !== -1){
    name = "500MG";
  }else if(product.slug.indexOf("1000") !== -1) {
    name = "1000MG";
  }else {
    name = "500MG";
  }
  return name;
}
export const renderTags = (product, sep = " | ", wrap) => {

  let flavor;
  let type;

  if (!product || !product.categories || product.categories.length <= 0) { return; }

  product.categories.forEach(function(category) {
    let capitalizedCategory = category.name.toUpperCase();
    switch (capitalizedCategory) {
      case "MIGHTY MANGO":
      case "FRENCH VANILLA":
      case "ZESTY LEMON":
      case "MINT BLISS":
      case "MENTHOL":
      case "COCOA BUTTER":
      case "ARGAN OIL":    
      case "FRESH WATERMELON": 
      case "ACAI BLAST":
        flavor = capitalizedCategory;
        break;
      case "PASSIONFRUIT":
        flavor = "PASSION FRUIT";
        break;
  
      case "UNWIND":
      case "BALANCE":
      case "ENERGIZE":
      case "REJUVENATE":
        type = capitalizedCategory;
        break;
      default:

    }
  });

  let tags = [];
  if (flavor) tags.push(flavor);
  if (type) tags.push(type);

  if (wrap) {
    tags = tags.map(tag => wrap(tag));
  }

  return tags.join(sep);

};

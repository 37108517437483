import React, {Component} from "react"
import anime from 'animejs/lib/anime.es.js';

class AnimatedLines extends Component {

  componentDidMount() {

    let i,
      j,
      len,
      lines,
      path,
      ref;

    lines = document.querySelectorAll(`.${this.props.className}`);

    ref = lines[0].querySelectorAll('path');
    for (i = j = 0, len = ref.length; j < len; i = ++j) {
      path = ref[i];
      anime({
        targets: path,
        d: [
          {
            value: [
              lines[0].querySelectorAll('path')[i].getAttribute('d'),
              lines[1].querySelectorAll('path')[i].getAttribute('d')
            ]
          }, {
            value: lines[2].querySelectorAll('path')[i].getAttribute('d')
          }, {
            value: lines[3].querySelectorAll('path')[i].getAttribute('d')
          }, {
            value: lines[4].querySelectorAll('path')[i].getAttribute('d')
          }, {
            value: lines[5].querySelectorAll('path')[i].getAttribute('d')
          }, {
            value: lines[0].querySelectorAll('path')[i].getAttribute('d')
          }
        ],

        duration: 10000,
        autoplay: true,
        loop: true,
        elasticity: 10000,
        direction: "alternate",

        easing: 'linear'
        // easing: 'easeOutElastic'
        // easing: function(el, i, total) {
        //   return function(t) {
        //     return Math.sin(t * (i + 1.5)) ** total;
        //   };
        // }
      });

    }

    //# sourceURL=coffeescript
  }

  render() {
    let props = this.props
    return (
      <div style={props.style}>
        <svg id="1line" className={props.className} version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 650 650">
          <g fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" transform="translate(1 1)">
            <path stroke="#F6BD17" d="M597.25,323.3 C600.45,355.06 591.18,387.85 582.36,415.3 C571.65,448.68 581.06,480.94 564.28,483.42"/>
            <path stroke="#3AC1CC" d="M547.87,175.13 C569.54,178.13 585.15,173.75 605.16,204.61 C632.81,247.25 588.94,257.1 597.25,323.3"/>
            <path stroke="#DE4826" d="M492.42,52.39 C503.9,83.78 489.24,108.09 500.34,134.13 C511.93,161.32 518.57,171.13 547.87,175.13"/>
            <path stroke="#F6BD17" d="M277.46,0 C326.19,1.06 352,18.81 379.18,30.49 C394.66,37.15 398.93,23.49 402.18,20.16 C411.78,10.28 427.03,12.86 445.05,15.59 C473.74,19.93 479.68,22.77 492.4,52.39"/>
            <path stroke="#3AC1CC" d="M42,105.24 C52.56,73 92.17,54.58 110.91,49.1 C156.24,35.84 179.91,24.99 222.06,8.84 C241.54,1.38 257.11,0.13 277.46,7.10542736e-15"/>
            <path stroke="#F6BD17" d="M63.13,369.8 C48.46,343 27.63,319.91 13,304.4 C-9,281 1.84,259.91 10.13,240 C14.7,229 22.91,215.27 27,204.13 C52.83,133.87 30.1,141.29 42,105.24"/>
            <path stroke="#FF7F40" d="M138.39,428.11 C128,396.77 82.7,405.69 63.13,369.8"/>
            <path stroke="#F6BD17" d="M162.26,522.17 C143.7,480.43 151.82,467.01 138.39,428.11"/>
            <path stroke="#3AC1CC" d="M484.79,571.2 C449.95,580.28 386.49,571.2 297.04,576.87 C234.68,580.87 181.79,563.87 162.26,522.17"/>
            <path stroke="#DE4826" d="M564.28,483.45 C539.44,487.24 532.28,478.64 524.63,485.45 C516.06,493.08 530.77,524.45 509.22,534.09 C486.38,544.28 505.22,564.91 484.79,571.2"/>
          </g>
        </svg>

        <svg id="2line" style={{
          display: "none"
        }} className={props.className} version="1.1" xmlns="http://www.w3.org/2000/svg">
          <g fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" transform="translate(0 1)">
            <path stroke="#F6BD17" d="M576.52,322.74 C593.96,365.74 600.39,415.74 594.65,437.11 C587.42,463.97 579.02,474.11 562.23,476.59"/>
            <path stroke="#3AC1CC" d="M519,136 C547.81,144.4 568.81,159.69 580.18,194.67 C594.88,239.9 553.59,271.16 576.52,322.74"/>
            <path stroke="#DE4826" d="M457,17.42 C474.92,45.19 458.11,62.22 467.73,88.84 C478.65,119.06 494.11,127.84 519.04,136"/>
            <path stroke="#F6BD17" d="M234.6,39.5 C292.17,45.13 299.2,46.06 312.72,44.02 C327.13,41.85 344.6,26.08 348.22,23.09 C363,10.92 396.14,1.35 414.34,0.21 C437.25,-1.23 448.49,4.81 457,17.42"/>
            <path stroke="#3AC1CC" d="M60.24,125.17 C70.76,92.9 86.61,89.38 103.77,80.06 C144.77,57.8 133.08,66.66 175.27,50.51 C194.75,43.05 206.94,37.57 234.6,39.51"/>
            <path stroke="#F6BD17" d="M14.09,362.33 C25.53,322.09 7.24,320.79 3,301.43 C-1.59,280.31 1.3,271.58 14.94,253.92 C22.22,244.49 49.94,222.33 55.11,211.66 C80.95,158.66 51.9,161.21 60.21,125.17"/>
            <path stroke="#FF7F40" d="M116.77,403.66 C116.39,362.33 0.77,403.66 14.09,362.33"/>
            <path stroke="#F6BD17" d="M153.8,511.45 C113.91,470.63 118.05,444 116.8,403.66"/>
            <path stroke="#3AC1CC" d="M355.51,570.74 C314.79,581.97 316.86,552.82 263.37,550.74 C200.93,548.33 185.58,543.68 153.8,511.44"/>
            <path stroke="#DE4826" d="M562.23,476.59 C537.39,480.39 487.66,478.43 462.87,490.16 C442.17,499.94 429.78,526.56 406.87,532.16 C359.81,543.64 375.96,564.46 355.53,570.75"/>
          </g>
        </svg>

        <svg id="3line" style={{
          display: "none"
        }} className={props.className} version="1.1" xmlns="http://www.w3.org/2000/svg">
          <g fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" transform="translate(1 1)">
            <path stroke="#F6BD17" d="M609.41,359.44 C616.74,383.44 608.41,418.74 602.71,440.09 C595.49,466.96 579.31,490.92 562.53,493.39"/>
            <path stroke="#3AC1CC" d="M554.71,191.72 C589.63,200.12 619.53,222.32 630.9,257.3 C645.59,302.53 595.74,307.86 609.41,359.44"/>
            <path stroke="#DE4826" d="M493.6,35.72 C504.48,65.86 489.84,105.65 499.46,132.28 C510.37,162.5 529.78,183.57 554.71,191.72"/>
            <path stroke="#F6BD17" d="M292.94,0 C350.51,8.09 356.34,22.87 369.69,25.81 C376.13,27.23 384.69,27.38 389.5,19.53 C402.34,-1.36 440.34,5.32 458.57,5 C480.41,4.65 488.57,21.75 493.57,35.7"/>
            <path stroke="#3AC1CC" d="M40.67,101.3 C68.02,60.84 127.59,66.16 146.67,62.23 C190.21,53.3 181.56,53.3 212.53,35.72 C230.71,25.43 255,0.13 292.94,0"/>
            <path stroke="#F6BD17" d="M60.48,352.19 C40.14,318.7 29.78,308.93 17,294.7 C2.47,278.65 -7,252.84 6.62,221.3 C17.45,196.3 18.04,189.59 22.53,178.6 C39.27,137.6 18.34,132 40.67,101.3"/>
            <path stroke="#FF7F40" d="M144.48,389 C133.95,357.82 66.9,380.07 60.48,352.17"/>
            <path stroke="#F6BD17" d="M171,478.88 C153.7,431.44 155.53,419.6 144.49,389.02"/>
            <path stroke="#3AC1CC" d="M409.32,565.39 C373.6,565.39 365.5,543.07 314.71,528 C254.81,510.23 188.85,526.88 170.99,478.88"/>
            <path stroke="#DE4826" d="M562.53,493.39 C529.87,501.49 517.28,496.3 498.89,502.67 C482.15,508.46 487.56,538.19 464.01,539.67 C415.74,542.76 431.36,565.36 409.32,565.36"/>
          </g>
        </svg>

        <svg id="4line" style={{
          display: "none"
        }} className={props.className} version="1.1" xmlns="http://www.w3.org/2000/svg">
          <g fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
            <path stroke="#F6BD17" d="M584.05,340.06 C597.05,361.75 605.8,417.3 600.05,438.65 C592.83,465.51 583.95,486.56 567.17,489.03"/>
            <path stroke="#3AC1CC" d="M544.28,185.54 C580.85,182.65 604.02,182.62 615.39,217.6 C630.09,262.83 559.71,291.6 584.05,340.06"/>
            <path stroke="#DE4826" d="M425.68,52.48 C436.57,82.62 446.68,114.57 456.3,141.19 C467.21,171.41 514.39,187.95 544.3,185.54"/>
            <path stroke="#F6BD17" d="M249.23,5.48 C300.58,25.24 302.03,30.77 315.38,33.71 C321.81,35.13 329.26,36.03 334.08,28.19 C346.92,7.3 364.57,11.78 382.29,16.08 C404.13,21.39 420.66,38.53 425.68,52.48"/>
            <path stroke="#3AC1CC" d="M58.08,55.38 C66.51,24.52 103.54,13.68 122,9.82 C155.28,2.82 157.11,4.62 192.62,1.82 C214.28,0.18 235.14,0.75 249.23,5.48"/>
            <path stroke="#F6BD17" d="M12,371.64 C14.89,340.78 13.2,333.55 9.59,315.26 C5.39,294.05 -6.26,275.41 7.42,243.88 C18.25,218.88 31.24,196.02 43.82,171.56 C74.67,111.56 53.46,77.79 58.04,55.38"/>
            <path stroke="#FF7F40" d="M96.65,476.25 C86.12,445.07 6.49,418.4 12,371.64"/>
            <path stroke="#F6BD17" d="M171.85,567.85 C136.95,538.01 107.68,506.85 96.65,476.25"/>
            <path stroke="#3AC1CC" d="M377.23,631.49 C344.69,644.03 332.64,617.49 272.85,606.9 C211.33,595.99 208.76,596.81 171.85,567.9"/>
            <path stroke="#DE4826" d="M567.18,489 C534.53,497.09 509.29,489.27 484.86,504.79 C461.72,519.49 461.12,551.19 428.57,570.23 C387.44,594.31 399.28,618.93 377.23,631.46"/>
          </g>
        </svg>

        <svg id="5line" style={{
          display: "none"
        }} className={props.className} version="1.1" xmlns="http://www.w3.org/2000/svg">
          <g fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" transform="translate(1)">
            <path stroke="#F6BD17" d="M583.06,280.58 C606.69,316.47 594.8,369.36 591.8,391.26 C588,419 590.13,493 561.85,496.94"/>
            <path stroke="#3AC1CC" d="M529.85,157 C566.43,154.11 594.28,167.61 599.43,184 C609.59,216.28 558.71,232.12 583.06,280.57"/>
            <path stroke="#DE4826" d="M453,40.94 C461.64,61.94 464.37,84.49 467.89,117.22 C471.32,149.16 510.89,158.15 529.89,157.03"/>
            <path stroke="#F6BD17" d="M287.62,6.15 C319.8,19.17 324.93,30 338,33.87 C345.13,35.98 351.42,39.57 363.67,25.68 C373.03,15.08 392.51,12.77 410.74,13.22 C433.44,13.78 443.3,19.91 452.97,40.94"/>
            <path stroke="#3AC1CC" d="M88.36,73.87 C111.43,49.87 135.57,38.92 151.8,29.4 C178.8,13.59 203.74,4.4 239.25,1.68 C260.94,-0.01 273.53,1.42 287.62,6.15"/>
            <path stroke="#F6BD17" d="M55.62,322.43 C58.51,281.68 36.08,269.22 23.43,255.43 C8.81,239.53 -11.71,229.21 8.36,201.31 C22.69,181.4 31.47,172.08 49.85,151.64 C89.48,107.54 73.29,92.47 88.36,73.87"/>
            <path stroke="#FF7F40" d="M137.48,416.19 C138.78,360.01 50.07,376.56 55.62,322.43"/>
            <path stroke="#F6BD17" d="M157.57,503.45 C125.39,465.12 137.49,444.84 137.48,416.19"/>
            <path stroke="#3AC1CC" d="M408.73,548.47 C361.85,557.4 374.34,545.1 314.22,536.56 C257.85,528.56 196.08,550.33 157.57,503.45"/>
            <path stroke="#DE4826" d="M561.85,496.94 C540.85,498.94 515.64,455.68 495.06,466.41 C477.2,475.73 494.99,514.84 457.29,514.05 C421.57,513.31 442.29,540.79 408.73,548.47"/>
          </g>
        </svg>

        <svg id="6line" style={{
          display: "none"
        }} className={props.className} version="1.1" xmlns="http://www.w3.org/2000/svg">
          <g fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" transform="translate(0 1)">
            <path stroke="#F6BD17" d="M573.1,300 C596.73,335.9 593.85,389.12 590.86,411 C587.07,438.73 588.7,480.46 560.42,484.37"/>
            <path stroke="#3AC1CC" d="M524.33,129.05 C576.62,137.24 587.39,170.05 593.2,186.21 C605.65,220.79 545.79,250.41 573.1,299.96"/>
            <path stroke="#DE4826" d="M438.28,14.52 C455.74,33.52 449.99,69.73 470.82,92.91 C492.3,116.8 505.35,124.74 524.33,129.05"/>
            <path stroke="#F6BD17" d="M258.58,11 C294.68,22.52 315.97,29 329.58,30 C341.49,30.91 349.29,33.83 356.7,19.78 C363.31,7.27 377.7,2.88 395.92,0.66 C418.68,-2.09 428.39,4 438.28,14.52"/>
            <path stroke="#3AC1CC" d="M59,68.56 C68,43 86.45,33.16 102.69,23.64 C129.69,7.83 154.48,3.47 190.1,3.77 C211.79,4 244.49,6.28 258.58,11"/>
            <path stroke="#F6BD17" d="M30.3,294.7 C33.19,253.95 16.3,244.44 7.86,227.77 C1.81,215.87 -6.92,201.77 15.47,175.68 C31.29,157.26 28.09,163.62 45.72,142.51 C80.25,101.15 51.32,94.57 59,68.56"/>
            <path stroke="#FF7F40" d="M124.74,378.79 C113.81,328.06 30.3,344.65 30.3,294.7"/>
            <path stroke="#F6BD17" d="M176.64,483.76 C144.45,445.44 130.78,409.49 124.74,378.76"/>
            <path stroke="#3AC1CC" d="M413.31,542.9 C366.42,551.83 378.2,523.56 318.09,515.03 C261.72,507.03 215.15,530.64 176.64,483.76"/>
            <path stroke="#DE4826" d="M560.42,484.35 C539.42,486.35 515.83,459.96 495.25,470.69 C477.39,480 494.32,514.98 456.62,514.2 C420.9,513.45 446.85,535.2 413.31,542.9"/>
          </g>
        </svg>
      </div>
    )
  }

}

export default AnimatedLines

import React, { Component } from "react";
import { Link } from "gatsby";
import "./header-cart.scss";

export default class HeaderCart extends Component {

  render () {

    let imageSrc;
    let textColor;
    if (this.props.isBlack) {
      imageSrc = require("../images/bag-alternate.svg"); // black bag
      textColor = "white";
    } else {
      imageSrc = require("../images/bag.svg"); // white bag
      textColor = "black";
    }

    return (
      <Link to="/cart" className="navbar-item header-cart header-cart-mobile">
        <span className="added-circle" />
        <img src={imageSrc} alt="" />
        <p className="header-cart-count" style={{ color: textColor }}>{this.props.quantity}</p>
      </Link>
    );
  }
}

import React, { Component, Fragment } from "react";
import auth from "../../lib/auth";
import TextField from "../form/textField.js";
import api from "../../api";

export default class LoginForm extends Component {
  constructor (props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      errorMessage: null,
      isForgetPassword: false,
      buttonLoading: false,
      resetLinkSent: false
    };
  }

  handleChange = event => {
    //console.log('event.target.name: ', event.target.name, ' event.target.value: ', event.target.value)
    this.setState({ [event.target.name]: event.target.value });
  };

  handleSubmit = e => {
    e.preventDefault();
    if (!this.state.isForgetPassword) {
      return this.handleLoginSubmit();
    } else {
      return this.handleForgetSubmit();
    }
  };

  handleLoginSubmit = async () => {
    const data = {
      username: this.state.email,
      password: this.state.password
    };

    console.log(`login customer with data:`, data);

    try {
      this.setState({ buttonLoading: true });
      await auth.login(data);
      this.setState({ buttonLoading: false });
      this.props.handleLoginSuccess();
    } catch (e) {
      this.setState({ buttonLoading: false, errorMessage: "The email or password you entered is incorrect" });
    }
  };

  handleForgetSubmit = async () => {
    const data = {
      username: this.state.email
    };

    console.log(`send reset password email with data:`, data);

    try {
      this.setState({ buttonLoading: true });
      await api.auth.lostpassword({ user_login: this.state.email });
      this.setState({ resetLinkSent: true });
    } catch (e) {
      this.setState({ errorMessage: "The email is incorrect" });
    } finally {
      this.setState({ buttonLoading: false });
    }
  };

  toggleForm = e => {
    e.target.blur();
    this.setState({ isForgetPassword: !this.state.isForgetPassword });
  };

  render () {

    if (this.state.resetLinkSent) {
      return (
        <section className="section">
          <h2>Password reset link<br /> sent to your email</h2>
          <br />
          <h4>If you did not find this letter, check the spam folder or try again later</h4>
        </section>
      );
    }

    return (

      <form onSubmit={this.handleSubmit} className="form is-marginless">
        {
          this.state.isForgetPassword ?
            <Fragment>
              <h1>No worries,<br />we're here<br />to help</h1>

              <h4>Enter the email address associated with your account, and we’ll email you a link to reset your password.</h4>
            </Fragment>
            :
            <Fragment>
              <h1>Well hello,<br />again</h1>

              <h4>Log into your account to manage subscriptions, orders and your personal info.</h4>
              <br />
              <p>Don’t have one? Shop our products to sign up.</p>
            </Fragment>
        }

        <br />

        {
          this.state.errorMessage &&
          <p className="has-text-danger">{this.state.errorMessage}</p>
        }
        <TextField label="Email Address" name="email" type="email" value={this.state.email} onChange={this.handleChange} />
        {
          !this.state.isForgetPassword &&
          <Fragment>
            <TextField label="Password" name="password" type="password" value={this.state.password} onChange={this.handleChange} />

            <label className="checkbox">
              <input type="checkbox" />
              Remember me
            </label>
          </Fragment>
        }


        <button className={`button is-primary is-fullwidth${this.state.buttonLoading ? " is-loading" : ""}`} type="submit">
          {this.state.isForgetPassword ? "SEND RESET LINK" : "LOG IN"}
        </button>
        <br />
        {this.props.guestReview ?
          <button className="button is-secondary is-fullwidth" onClick={this.props.handleLoginSuccess} style={{marginTop:'-15px'}}>
            SEND REVIEW AS A GUEST
          </button>
          :
          false
        }
        <button type="button" className="button is-transparent is-fullwidth" onClick={this.toggleForm}>
          {this.state.isForgetPassword ? "RETURN TO LOGIN" : "FORGET PASSWORD?"}
        </button>

      </form>
    );
  }

}

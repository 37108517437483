import React, {Fragment} from "react"
import "./accordion.scss"
import anime from 'animejs/lib/anime.es.js';

const AnimatedUnderline = (props) => {
  const id = '_' + Math.random().toString(36).substr(2, 9);

  const handleMouseEnter = () => {


    anime.remove(`#${id}`)

    anime({
      targets: `#${id}`,
      width: "100%",
      easing: 'easeInOutQuad',
      duration: 500
    });

  }

  const handleMouseLeave = () => {



    anime.remove(`#${id}`)
    anime({
      targets: `#${id}`,
      width: "0%",
      easing: 'easeInOutQuad',
      duration: 500
    });

  }

  return (
    <Fragment>
    <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} className="animated-underline-wrapper">
    {props.children}
    <div id={id} className="link-underline" style={{
      height: "2px",
      width: "0%",
      backgroundColor: "white"
    }}/>
    </div>

    </Fragment>
  )
}

export default AnimatedUnderline

import React from "react"

const AcceptedPaymentMethods = () => {
  return (
    <div className="columns is-centered is-vcentered is-mobile is-gapless">
      <div className="column has-text-centered">
        <img src={require(`../images/payment/visa.svg`)} className="is-inline" style={{height:"25px"}} alt=""/>
      </div>
      <div className="column has-text-centered">
      <img src={require(`../images/payment/mastercard.svg`)} className="is-inline" style={{height:"25px"}} alt=""/>
      </div>
      <div className="column has-text-centered">
      <img src={require(`../images/payment/american-express.svg`)}  style={{height:"25px"}} alt=""/>
      </div>
      <div className="column has-text-centered">
      <img src={require(`../images/payment/discover.svg`)} className="is-inline" style={{height:"25px"}} alt=""/>
      </div>
      <div className="column has-text-centered">
      <img src={require(`../images/payment/jcb.svg`)} className="is-inline" style={{height:"25px"}} alt=""/>
      </div>
      <div className="column has-text-centered">
      <img src={require(`../images/payment/union-pay.svg`)} className="is-inline" style={{height:"25px"}} alt=""/>
      </div>
    </div>
  )
}

export default AcceptedPaymentMethods

import React from "react";
import PropTypes from "prop-types";

export default function CartTotalRowModal ({ title, bold, text, children }) {
  return (
    <div className="level is-mobile">
      <div className="level-left">
        <div className="level-item">
          <h4 className={`${ typeof bold !== "undefined" ? "has-text-weight-bold" :"" } has-text-left ${ typeof text !== "undefined" ? text :"" }`}>{title}</h4>
        </div>
      </div>
      <div className="level-right">
        <div className="level-item">
          <h4 className={`${typeof bold !== "undefined" ? "has-text-weight-bold" :"" } has-text-left ${ typeof text !== "undefined" ? text :"" }`}>{children}</h4>
        </div>
      </div>
    </div>
  );
}

CartTotalRowModal.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
}
